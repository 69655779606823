<template>
  <div class="content-container">
    <div class="center-container">
      <p class="title">心电生理数据总汇</p>
      <table class="table">
        <tr>
          <th>指标</th>
          <th>心搏</th>
          <th>最快心率</th>
          <th>最慢心率</th>
          <th>平均心率</th>
          <th>停搏漏搏</th>
          <th>房性早搏</th>
          <th>室性早搏</th>
        </tr>
        <tr v-for="(row, index) in result" :key="index">
          <td v-for="(data, i) in row" :key="row[0] + i">{{data}}</td>
        </tr>
      </table>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="$router.back()">返回</Button>
      <Button type="warning" size="large" @click="goToEvaluate">心电预警评估</Button>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			result: [],
		};
	},
	created() {
		this.getData();
	},
	methods: {
		getData() {
			MemberService.getECGData(this.$route.params.record_id).then((data) => {
				this.result = JSON.parse(data.nr);
			});
		},
		goToEvaluate() {
			this.$router.push({ name: 'ECGEvaluate' });
		},
	},
};
</script>

<style lang="less" scoped>
.content-container {
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  .center-container {
    width: 1000px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
  }
  .table {
    width: 100%;
    th, td {
      border: 1px solid;
      padding: 5px 10px;
      text-align: center;
    }
  }
  .symbol-box {
    margin-top: 20px;
  }
}
</style>
